import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import EdgesensorHighRoundedIcon from '@mui/icons-material/EdgesensorHighRounded';
import LockResetIcon from '@mui/icons-material/LockReset';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

const items = [
	{
		icon: <LockResetIcon/>,
		title: 'Password-Protected Encryption',
		description:
			'Encrypt your secrets with a password provided by the user, ensuring that only authorized recipients can access the confidential information. Provide an additional layer of security beyond simple text encryption.\n',
		imageLight: 'url("/images/password-protected-encryption.webp")',
		imageDark:  'url("/images/password-protected-encryption.webp")',
	},
	{
		icon: <EdgesensorHighRoundedIcon/>,
		title: 'Multi-Channel Delivery (coming soon)',
		description:
			'Send encrypted messages via email and SMS, giving you the flexibility to reach your recipients through their preferred communication channels. Whether it\'s a client update, a confidential memo, or a sensitive detail, we ensures secure delivery across multiple channels.',
		imageLight: 'url("/images/multi-channel-delivery.webp")',
		imageDark: 'url("/images/multi-channel-delivery.webp")',
	},
	{
		icon: <AutoFixHighIcon/>,
		title: 'Customizable Message Templates (coming soon)',
		description:
			'Personalize your messages with your business name and logo, creating a professional and branded experience for your recipients. Stand out from competitors by delivering messages that reflect your brand identity.',
		imageLight: 'url("/images/custom-template.webp")',
		imageDark: 'url("/images/custom-template.webp")',
	},
];

export default function Features() {
	const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

	const handleItemClick = (index) => {
		setSelectedItemIndex(index);
	};

	const selectedFeature = items[selectedItemIndex];

	return (
		<Container id="features" sx={{py: {xs: 8, sm: 16}}}>
			<Grid container spacing={6}>
				<Grid item xs={12} md={6}>
					<div>
						<Typography component="h2" variant="h4" color="text.primary">
							What our product offers?
						</Typography>
						<Typography
							variant="body1"
							color="text.secondary"
							sx={{mb: {xs: 2, sm: 4}}}
						>
							Our product is the ultimate solution for securely sharing confidential information with your
							clients and colleagues. With SecureShare Pro, you can ensure that your sensitive
							information remains secure while delivering a professional and branded experience to your
							recipients.
						</Typography>
					</div>
					<Grid container item gap={1} sx={{display: {xs: 'auto', sm: 'none'}}}>
						{items.map(({title}, index) => (
							<Chip
								key={index}
								label={title}
								onClick={() => handleItemClick(index)}
								sx={{
									borderColor: (theme) => {
										if (theme.palette.mode === 'light') {
											return selectedItemIndex === index ? 'primary.light' : '';
										}
										return selectedItemIndex === index ? 'primary.light' : '';
									},
									background: (theme) => {
										if (theme.palette.mode === 'light') {
											return selectedItemIndex === index ? 'none' : '';
										}
										return selectedItemIndex === index ? 'none' : '';
									},
									backgroundColor: selectedItemIndex === index ? 'primary.main' : '',
									'& .MuiChip-label': {
										color: selectedItemIndex === index ? '#fff' : '',
									},
								}}
							/>
						))}
					</Grid>
					<Box
						component={Card}
						variant="outlined"
						sx={{
							display: {xs: 'auto', sm: 'none'},
							mt: 4,
						}}
					>
						<Box
							sx={{
								backgroundImage: (theme) =>
									theme.palette.mode === 'light'
										? items[selectedItemIndex].imageLight
										: items[selectedItemIndex].imageDark,
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								minHeight: 280,
							}}
						/>
						<Box sx={{px: 2, pb: 2}}>
							<Typography color="text.primary" variant="body2" component="h2" fontWeight="bold">
								{selectedFeature.title}
							</Typography>
							<Typography color="text.secondary" variant="body2" sx={{my: 0.5}}>
								{selectedFeature.description}
							</Typography>
						</Box>
					</Box>
					<Stack
						direction="column"
						justifyContent="center"
						alignItems="flex-start"
						spacing={2}
						useFlexGap
						sx={{width: '100%', display: {xs: 'none', sm: 'flex'}}}
					>
						{items.map(({icon, title, description}, index) => (
							<Card
								key={index}
								variant="outlined"
								component={Button}
								onClick={() => handleItemClick(index)}
								sx={{
									p: 3,
									height: 'fit-content',
									width: '100%',
									background: 'none',
									backgroundColor:
										selectedItemIndex === index ? 'action.selected' : undefined,
									borderColor: (theme) => {
										if (theme.palette.mode === 'light') {
											return selectedItemIndex === index
												? 'primary.light'
												: 'grey.200';
										}
										return selectedItemIndex === index ? 'primary.dark' : 'grey.800';
									},
								}}
							>
								<Box
									sx={{
										width: '100%',
										display: 'flex',
										textAlign: 'left',
										flexDirection: {xs: 'column', md: 'row'},
										alignItems: {md: 'center'},
										gap: 2.5,
									}}
								>
									<Box
										sx={{
											color: (theme) => {
												if (theme.palette.mode === 'light') {
													return selectedItemIndex === index
														? 'primary.main'
														: 'grey.300';
												}
												return selectedItemIndex === index
													? 'primary.main'
													: 'grey.700';
											},
										}}
									>
										{icon}
									</Box>
									<Box sx={{textTransform: 'none'}}>
										<Typography
											color="text.primary"
											variant="body2"
											fontWeight="bold"
											component="h2"
										>
											{title}
										</Typography>
										<Typography
											color="text.secondary"
											variant="body2"
											sx={{my: 0.5}}
										>
											{description}
										</Typography>
									</Box>
								</Box>
							</Card>
						))}
					</Stack>
				</Grid>
				<Grid
					item
					xs={12}
					md={6}
					sx={{display: {xs: 'none', sm: 'flex'}, width: '100%'}}
				>
					<Card
						variant="outlined"
						sx={{
							height: '100%',
							width: '100%',
							display: {xs: 'none', sm: 'flex'},
							pointerEvents: 'none',
						}}
					>
						<Box
							sx={{
								m: 'auto',
								width: 420,
								height: 500,
								backgroundSize: 'cover',
								backgroundImage: (theme) =>
									theme.palette.mode === 'light'
										? items[selectedItemIndex].imageLight
										: items[selectedItemIndex].imageDark,
							}}
						/>
					</Card>
				</Grid>
			</Grid>
		</Container>
	);
}