import * as React from 'react';
import Box from '@mui/material/Box';
import {useLoaderData, useNavigation, useParams} from "react-router-dom";
import axios from "axios";
import {alpha, Avatar, Snackbar} from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { useEffect, useState} from "react";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import KeyIcon from '@mui/icons-material/Key';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HistoryIcon from '@mui/icons-material/History';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import {blue} from "@mui/material/colors";
import {Helmet} from "react-helmet";
import {GoogleReCaptcha} from "react-google-recaptcha-v3";

export const secretsIdPageLoader = async ({params}) => {
	try {
		const response = await axios.get(`${process.env.REACT_APP_API_URL}/secrets/verify/${params.secretId}`);
		return response.data;
	} catch (e) {
		return {error: e.response.data};
	}
}

const decrypt = async (id, password, token) => {
	try {
		const response = await axios.post(`${process.env.REACT_APP_API_URL}/secrets/decrypt/${id}`, {password}, {headers: {recaptcha: token}});

		return response.data;
	} catch (e) {
		return {error: e.response.data};
	}
}

export default function SecretsIdPage() {
	const {secretId} = useParams();
	const data = useLoaderData();
	const navigation = useNavigation();
	const [error, setError] = useState(null);
	const [icon, setIcon] = useState(<LockOutlinedIcon/>);
	const [password, setPassword] = useState("");
	const [passwordError, setPasswordError] = useState(null);
	const [unlocked, setUnlocked] = useState(false);
	const [content, setContent] = useState(null);
	const [token, setToken] = useState(null);

	const submit = async () => {
		const response = await decrypt(secretId, password, token);
		if (response.error && response.error.invalid) {
			setPasswordError("The password is not valid")
		}
		if (response.data) {
			setContent(response.data);
			setUnlocked(true);
			setIcon(<LockOpenOutlinedIcon/>);
		}

	}

	useEffect(() => {
		if (data.error) {
			if (data.error.idInvalid) {
				setError({title: "Invalid Secret ID", description: "The provided secret ID is not valid."});
				setIcon(<HighlightOffIcon/>);
			}
			if (data.error.used) {
				setError({title: "Secret Used", description: "The secret has been already been used."});
				setIcon(<HistoryIcon/>);
			}
			if (data.error.notFound) {
				setError({title: "Secret not found", description: "The provided secret is not found."});
				setIcon(<HighlightOffIcon/>);
			}
		}
	}, [data]);

	const [snackbarText, setSnackbarText] = React.useState("");
	const [snackbarOpen, setSnackbarOpen] = React.useState(false);
	const snackbarHandleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setSnackbarOpen(false);
	};

	const snackbar = (text) => {
		setSnackbarText(text);
		setSnackbarOpen(true);
		setTimeout(() => setSnackbarOpen(false), 5000);
	}

	const copyToClipboard = (text, message) => {
		navigator.clipboard.writeText(text)
			.then(() => {
				snackbar(message || 'Your text has been copied!');
			})
			.catch((error) => {
				console.error('Failed to copy text: ', error);
			});
	};

	return (
		<>
			<Helmet>
				<title>Unlock your secret text | BizShareKit</title>
				<meta name="description" content="Unlock your text secret secure with our app."/>
			</Helmet>
			<GoogleReCaptcha onVerify={(t) => setToken(t)}/>
			<Box
				id="hero"
				sx={(theme) => ({
					width: '100%',
					backgroundImage:
						theme.palette.mode === 'light'
							? 'linear-gradient(180deg, #CEE5FD, #FFF)'
							: `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
					backgroundSize: '100% 20%',
					backgroundRepeat: 'no-repeat',
				})}
			>
				<Container
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						pt: {xs: 14, sm: 20},
						pb: {xs: 8, sm: 12},
					}}
				>
					<Box
						sx={(theme) => ({

							alignSelf: 'center',
							width: '100%',
							backgroundSize: 'cover',
							borderRadius: '10px',
							outline: '1px solid',
							outlineColor:
								theme.palette.mode === 'light'
									? alpha('#BFCCD9', 0.5)
									: alpha('#9CCCFC', 0.1),
							boxShadow:
								theme.palette.mode === 'light'
									? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
									: `0 0 24px 12px ${alpha('#033363', 0.2)}`,
							minHeight: 480
						})}
					>

						<Box
							sx={{
								margin: 8,
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
							}}
						>
							<Avatar sx={{m: 1, bgcolor: blue[500]}}>
								{icon}
							</Avatar>
							{navigation.state === "loading" && (
								<Typography component="h1" variant="h4">
									Loading
								</Typography>
							)}
							{error && (
								<>
									<Typography component="h1" variant="h4">
										{error.title}
									</Typography>
									<Typography component="p" variant="p">
										{error.description}
									</Typography>
								</>
							)}
							{!error && unlocked && (
								<>
									<TextField
										InputProps={{
											endAdornment: <Button
												onClick={() => copyToClipboard(content, "Your secret text has been copied!")}><CopyAllIcon/> Copy</Button>
										}}
										margin="normal"
										value={content}
										label="Secret text"
										multiline
										rows={10}
										fullWidth
									/>
								</>
							)}
							{!error && !unlocked && (
								<>
									<Typography component="h1" variant="h4">
										Unlock your secret
									</Typography>
									<Typography component="p" variant="p">
										Use the password from provided by the sender
									</Typography>
									<Box sx={{mt: 1}}>
										<TextField
											InputProps={{startAdornment: <KeyIcon sx={{mr: 1}}/>}}
											margin="normal"
											value={password}
											onChange={(e) => setPassword(e.target.value)}
											required
											fullWidth
											name="password"
											label="Password"
											type="password"
											placeholder="Insert the password here"
											error={!!passwordError}
											helperText={passwordError}
										/>
										<Button
											fullWidth
											variant="contained"
											sx={{mt: 3, mb: 2}}
											onClick={submit}
										>
											Unlock
										</Button>
									</Box>
								</>
							)}
						</Box>
					</Box>
				</Container>
			</Box>
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={5000}
				onClose={snackbarHandleClose}
				message={snackbarText}
			/>
		</>
	);
}