import {Outlet} from "react-router-dom";
import Footer from "./Footer"
import {createTheme, ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import AppAppBar from "./AppAppBar";
import * as React from "react";
import Box from "@mui/material/Box";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import PropTypes from "prop-types";

function ToggleCustomTheme({showCustomTheme, toggleCustomTheme}) {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				width: '100dvw',
				position: 'fixed',
				bottom: 24,
			}}
		>
			<ToggleButtonGroup
				color="primary"
				exclusive
				value={showCustomTheme}
				onChange={toggleCustomTheme}
				aria-label="Platform"
				sx={{
					backgroundColor: 'background.default',
					'& .Mui-selected': {
						pointerEvents: 'none',
					},
				}}
			>
				<ToggleButton value>
					<AutoAwesomeRoundedIcon sx={{fontSize: '20px', mr: 1}}/>
					Custom theme
				</ToggleButton>
				<ToggleButton value={false}>Material Design 2</ToggleButton>
			</ToggleButtonGroup>
		</Box>
	);
}

ToggleCustomTheme.propTypes = {
	showCustomTheme: PropTypes.shape({
		valueOf: PropTypes.func.isRequired,
	}).isRequired,
	toggleCustomTheme: PropTypes.func.isRequired,
};

export default function Layout() {
	const [mode, setMode] = React.useState(localStorage.getItem('mode') || 'light');
	const theme = createTheme({
		palette: {mode},
		overrides: {
			MuiButton: {
				root: {
					borderRadius: 8,
				},
			},
		},
	});

	const toggleColorMode = () => {
		setMode((prev) => {
			const mode = prev === 'dark' ? 'light' : 'dark';
			localStorage.setItem("mode", mode);
			return mode;
		});
	};

	return (
		<>
			<ThemeProvider theme={theme}>
				<CssBaseline/>
				<AppAppBar mode={mode} toggleColorMode={toggleColorMode}/>
				<Outlet/>
				<Footer/>
			</ThemeProvider>
		</>
	)
}