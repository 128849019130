import * as React from 'react';
import Box from '@mui/material/Box';
import {useRouteError} from "react-router-dom";
import Typography from "@mui/material/Typography";
import {Helmet} from "react-helmet";

export default function NotFoundPage() {
	const error = useRouteError();
	console.error(error);

	return (
		<>
			<Helmet>
				<title>Page not found | BizShareKit</title>
				<meta name="description" content="The page you are looking for is not found." />
			</Helmet>
			<Box sx={{ bgcolor: 'background.default' , mt: 25, mb: 15 }}>
				<Typography variant="h1" align="center">Not found</Typography>
				<Typography variant="h5" align="center">The page you are looking is not found</Typography>
			</Box>
		</>
	);
}