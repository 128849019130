import * as React from 'react';
import {
	AccordionActions,
	alpha, Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText,
	Select, Snackbar
} from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import KeyIcon from '@mui/icons-material/Key';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import {TypeAnimation} from 'react-type-animation';
import * as Yup from 'yup';
import axios from 'axios';
import {useState} from "react";
import {GoogleReCaptcha} from "react-google-recaptcha-v3";

const initialValue = {
	text: "",
	expire: 7,
	password: "",
}

const generateRandomString = (length) => {
	const array = new Uint8Array(length);
	window.crypto.getRandomValues(array);
	return Array.from(array, (byte) => byte.toString(36)).join('').slice(0, length);
};

const validationSchema = Yup.object().shape({
	text: Yup.string().required('Secret text is required').min(1, 'Secret text needs to be longer than 1 character.'),
	password: Yup.string().required('Secret password is required').min(4, 'Secret password needs to be longer than 4 character.'),
});

const createSecret = async (data) => {
	try {
		const response = await axios.post(`${process.env.REACT_APP_API_URL}/secrets`, data, { headers: { recaptcha: data.token } });
		return response.data;
	} catch (e) {
		console.error(e);
	}
}

export default function Hero() {

	const [data, setData] = React.useState(initialValue);
	const [open, setOpen] = React.useState(false);
	const [dialogData, setDialogData] = React.useState({});
	const [records, setRecords] = React.useState([]);
	const [errors, setErrors] = React.useState({});
	const [token, setToken] = useState(null);

	const titleArray = ['Share passwords', 'Share secrets', 'Share information', 'Share text'];

	const handleChange = (event) => {
		setData(prevState => ({...prevState, [event.target.name]: event.target.value}));
	};

	const openDialog = (data) => {
		setDialogData({
			secretUrl: data.secretUrl,
			secretPassword: data.secretPassword,
			oneClickUrl: data.oneClickUrl,
		});
		setOpen(true);
	}

	const generatePassword = () => {
		setData(prevState => ({...prevState, password: generateRandomString(20)}))
	}

	const submit = async () => {
		try {
			await validationSchema.validate(data, {abortEarly: false});
		} catch (validationErrors) {
			// Validation failed, set errors state
			const errors = {};
			validationErrors.inner.forEach((error) => {
				errors[error.path] = error.message;
			});
			setErrors(errors);
			return;
		}

		const secretData = await createSecret({...data, token: token});
		const record = {
			...data,
			secretUrl: `${process.env.REACT_APP_UI_URL}/secrets/${secretData.id}`,
			secretPassword: data.password,
			oneClickUrl: `${process.env.REACT_APP_UI_URL}/secrets/${secretData.id}/${encodeURIComponent(data.password)}`,
		};
		setRecords(prevState => [...prevState, record]);
		openDialog(record);
		setData(initialValue);
	}

	const [snackbarText, setSnackbarText] = React.useState("");
	const [snackbarOpen, setSnackbarOpen] = React.useState(false);
	const snackbarHandleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	const snackbar = (text) => {
		setSnackbarText(text);
		setSnackbarOpen(true);
		setTimeout(() => setSnackbarOpen(false), 5000);
	}

	const copyToClipboard = (text, message) => {
		navigator.clipboard.writeText(text)
			.then(() => {
				snackbar(message || 'Your text has been copied!');
			})
			.catch((error) => {
				console.error('Failed to copy text: ', error);
			});
	};

	return (
		<Box
			id="hero"
			sx={(theme) => ({
				width: '100%',
				backgroundImage:
					theme.palette.mode === 'light'
						? 'linear-gradient(180deg, #CEE5FD, #FFF)'
						: `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
				backgroundSize: '100% 20%',
				backgroundRepeat: 'no-repeat',
			})}
		>
			<Container
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					pt: {xs: 14, sm: 20},
					pb: {xs: 8, sm: 12},
				}}
			>
				<Stack spacing={2} useFlexGap sx={{width: {xs: '100%', sm: '70%'}}}>
					<Typography
						variant="h1"
						sx={{
							display: 'flex',
							flexDirection: {xs: 'column', md: 'row'},
							alignSelf: 'center',
							textAlign: 'center',
							fontSize: 'clamp(3.5rem, 10vw, 4rem)',
						}}
					>
						<TypeAnimation
							sequence={titleArray.flatMap(item => [item, 3000])}
							speed={50}
							repeat={Infinity}
						/>
						<Typography
							component="span"
							variant="h1"
							sx={{
								fontSize: 'clamp(3rem, 10vw, 4rem)',
								color: (theme) =>
									theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
							}}
						>
							secure
						</Typography>
					</Typography>
					<Typography
						textAlign="center"
						color="text.secondary"
						sx={{alignSelf: 'center', width: {sm: '100%', md: '80%'}}}
					>
						Safeguard your sensitive information with ease, using our encrypted and user-friendly interface.
						Our advanced encryption technology ensures that your data remains protected at all times.
						Say goodbye to risky and unreliable messaging apps.
					</Typography>
				</Stack>
				<Box
					sx={(theme) => ({
						mt: {xs: 8, sm: 10},
						alignSelf: 'center',
						width: '100%',
						backgroundSize: 'cover',
						borderRadius: '10px',
						outline: '1px solid',
						outlineColor:
							theme.palette.mode === 'light'
								? alpha('#BFCCD9', 0.5)
								: alpha('#9CCCFC', 0.1),
						boxShadow:
							theme.palette.mode === 'light'
								? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
								: `0 0 24px 12px ${alpha('#033363', 0.2)}`,
					})}
				>
					<Stack sx={{m: 5}} spacing={3}>
						<GoogleReCaptcha onVerify={(t) => setToken(t)}/>
						<TextField
							label="Secret text"
							placeholder="Insert your secret text here"
							onChange={handleChange}
							name="text"
							value={data.text}
							multiline
							rows={8}
							error={errors.text}
							errorText={errors.text}
							helperText={errors.text}
						/>
						<FormControl>
							<InputLabel>Secret expire in</InputLabel>
							<Select
								value={data.expire}
								label="Secret expire in"
								onChange={handleChange}
								name="expire"
							>
								<MenuItem value={1}>1 Day</MenuItem>
								<MenuItem value={7}>1 Week</MenuItem>
								<MenuItem value={30}>1 Month</MenuItem>
							</Select>
						</FormControl>
						<TextField
							value={data.password}
							name="password"
							label="Secret password"
							placeholder="Insert your secret text here"
							onChange={handleChange}
							// helperText="Please make sure you keep it safe, only with this password can be decoded"
							InputProps={{endAdornment: <Button onClick={generatePassword}>Generate</Button>}}
							error={errors.password}
							helperText={errors.password}
						/>
						<Accordion>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon/>}
								aria-controls="panel3-content"
								id="panel3-header"
							>
								<AddIcon/> Add more features (multi-channel email/SMS, template, business data)
							</AccordionSummary>
							<AccordionDetails>
								<Stack spacing={2}>

									<TextField
										label="Recepient Email"
										placeholder="Insert the recepient email"
										helperText="The recepient will receive an email with secret URL and password"
										disabled
										InputProps={{endAdornment: <Button disabled>COMING SOON</Button>}}
									/>
									<TextField
										label="Recepient Phone Number"
										placeholder="Insert the recepient phone"
										helperText="The recepient will receive a SMS with secret URL and password"
										disabled
										InputProps={{endAdornment: <Button disabled>COMING SOON</Button>}}
									/>
									<TextField
										label="Recepient Name"
										placeholder="Insert the recepient name"
										helperText="The email and SMS will be personalized"
										disabled
										InputProps={{endAdornment: <Button disabled>COMING SOON</Button>}}
									/>
									<TextField
										label="Template"
										value={["Hello {RECIPIENT_NAME},", "", "You received a secret message from {BUSINESS_NAME} to access it please use {SECRET_URL} with the following password {SECRET_PASSWORD}."].join('\n')}
										multiline
										rows={4}
										disabled
										InputProps={{endAdornment: <Button disabled>COMING SOON</Button>}}
									/>
									<Divider>YOUR BUSINESS INFORMATION</Divider>
									<TextField
										label="Business Name"
										placeholder="Insert the business name"
										helperText="The email and SMS will be personalized with your business name"
										disabled
										InputProps={{endAdornment: <Button disabled>COMING SOON</Button>}}
									/>
									<TextField
										label="Business Logo"
										placeholder="Insert the business image"
										helperText="The email and SMS will be personalized with your business logo"
										disabled
										InputProps={{endAdornment: <Button disabled>COMING SOON</Button>}}
									/>
								</Stack>
							</AccordionDetails>
							<AccordionActions>
								{/*<Button>Buy PREMIUM</Button>*/}
							</AccordionActions>
						</Accordion>
						<Button fullWidth variant="contained" sx={{borderRadius: 8}} onClick={submit}>Share</Button>
					</Stack>
					{!!records?.length && (
						<Box sx={{m: 5}}>
							<nav aria-label="main mailbox folders">
								<List>
									{records.map(record => (
											<ListItem disablePadding>
												<ListItemButton onClick={() => openDialog(record)}>
													<ListItemIcon>
														<KeyIcon/>
													</ListItemIcon>
													<ListItemText primary={record.text?.substring(0, 30) + "..."}/>
												</ListItemButton>
											</ListItem>
										)
									)}
								</List>
							</nav>
						</Box>
					)}
				</Box>
			</Container>
			<Dialog
				open={open}
				onClose={() => setOpen(false)}
			>
				<DialogTitle>Share your secret safe</DialogTitle>
				<DialogContent>
					<Typography>
						URL link for the shared content along with a separate password. Recipients
						need to enter the password to access the content securely. Provides an additional
						layer of
						security, ensuring only authorized users can access the shared information.
					</Typography>
					<Stack spacing={2} sx={{marginTop: 3}}>
						<TextField
							label="Secret URL"
							autoFocus
							fullWidth
							variant="outlined"
							value={dialogData.secretUrl}
							InputProps={{endAdornment: <Button onClick={() => copyToClipboard(dialogData.secretUrl, "Secret URL has been copied to clipboard!")}>Copy</Button>}}
						/>
						<TextField
							label="Secret Password"
							autoFocus
							fullWidth
							variant="outlined"
							value={dialogData.secretPassword}
							InputProps={{endAdornment: <Button onClick={() => copyToClipboard(dialogData.secretPassword, "Secret password has been copied to clipboard!")}>Copy</Button>}}
							helperText="sharing confidential or sensitive information that require strict access control"
						/>
						<Divider>OR</Divider>
						<Typography>
							One-click URL link that recipients can click on to access the shared content
							instantly. No need for them to enter a password. Quick and convenient, but less
							secure as
							anyone with the link can access the content.
						</Typography>
						<TextField
							label="One-click URL"
							autoFocus
							fullWidth
							variant="outlined"
							value={dialogData.oneClickUrl}
							InputProps={{endAdornment: <Button onClick={() => copyToClipboard(dialogData.oneClickUrl, "One-click URL has been copied to clipboard!")}>Copy</Button>}}
							helperText="sharing of non-sensitive information among trusted individuals or within a closed group"
						/>
					</Stack>
				</DialogContent>
				{/*<DialogActions>*/}
				{/*	<Button onClick={() => setOpen(false)}>Close</Button>*/}
				{/*</DialogActions>*/}
			</Dialog>
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={5000}
				onClose={snackbarHandleClose}
				message={snackbarText}
			/>
		</Box>
	);
}