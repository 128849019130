import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FAQ() {
	const [expanded, setExpanded] = React.useState(false);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<Container
			id="faq"
			sx={{
				pt: {xs: 4, sm: 12},
				pb: {xs: 8, sm: 16},
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				gap: {xs: 3, sm: 6},
			}}
		>
			<Typography
				component="h2"
				variant="h4"
				color="text.primary"
				sx={{
					width: {sm: '100%', md: '60%'},
					textAlign: {sm: 'left', md: 'center'},
				}}
			>
				Frequently asked questions
			</Typography>
			<Box sx={{width: '100%'}}>
				<Accordion
					expanded={expanded === 'panel1'}
					onChange={handleChange('panel1')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon/>}
						aria-controls="panel1d-content"
						id="panel1d-header"
					>
						<Typography component="h3" variant="h6">
							What is text encryption?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography
							variant="body2"
							gutterBottom
							sx={{maxWidth: {sm: '100%', md: '70%'}}}
						>
							Text encryption is the process of transforming readable text (plaintext) into unreadable
							text (ciphertext) using an encryption algorithm and a secret key or password. This ensures
							that the information remains confidential, and only those who have the correct key or
							password can decrypt and access the original text.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					expanded={expanded === 'panel2'}
					onChange={handleChange('panel2')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon/>}
						aria-controls="panel2d-content"
						id="panel2d-header"
					>
						<Typography component="h3" variant="h6">
							What happens if I forget the password used for encryption?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography
							variant="body2"
							gutterBottom
							sx={{maxWidth: {sm: '100%', md: '70%'}}}
						>
							If you forget the password used for encryption, the encrypted text cannot be decrypted by
							the app. There is no way to recover the lost password due to the nature of secure encryption
							algorithms. Always keep a secure record of the password if necessary, and ensure it is
							stored separately from the encrypted data.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					expanded={expanded === 'panel3'}
					onChange={handleChange('panel3')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon/>}
						aria-controls="panel3d-content"
						id="panel3d-header"
					>
						<Typography component="h3" variant="h6">
							Can the secret text be read by us or be stolen?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography
							variant="body2"
							gutterBottom
							sx={{maxWidth: {sm: '100%', md: '70%'}}}
						>
							No, the secret text cannot be read by us, nor is it easily susceptible to theft. The text
							you input is encrypted using your password before it is stored on our servers. Since we do
							not store or have access to your encryption passwords, we cannot decrypt or read the
							encrypted text. This architecture ensures that your data remains confidential and can only
							be accessed by those who know the specific password. We recommend sharing the encryption
							password securely through a separate communication channel to further protect your data's
							privacy.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					expanded={expanded === 'panel4'}
					onChange={handleChange('panel4')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon/>}
						aria-controls="panel4d-content"
						id="panel4d-header"
					>
						<Typography component="h3" variant="h6">
							How should I choose a strong password?
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography
							variant="body2"
							gutterBottom
							sx={{maxWidth: {sm: '100%', md: '70%'}}}
						>
							A strong password is crucial for maintaining the security of your encrypted data. We
							recommend using a password that includes a mix of uppercase and lowercase letters, numbers,
							and special characters. It should be at least 12 characters long. Avoid common words or
							easily guessable information like birthdays or simple sequences.
						</Typography>
					</AccordionDetails>
				</Accordion>
			</Box>
		</Container>
	);
}