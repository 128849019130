import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';


const logoStyle = {
	width: '140px',
	height: 'auto',
	marginLeft: 30,
};

function Copyright() {
	return (
		<Typography variant="body2" color="text.secondary" mt={1}>
			{'Copyright © '}
			<Link href="https://zenevo.ro/">Zenevo&nbsp;</Link>
			{new Date().getFullYear()}
		</Typography>
	);
}

export default function Footer() {
	return (
		<Container
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				gap: { xs: 4, sm: 8 },
				py: { xs: 8, sm: 10 },
				textAlign: { sm: 'center', md: 'left' },
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: { xs: 'column', sm: 'row' },
					width: '100%',
					justifyContent: 'space-between',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 4,
						minWidth: { xs: '100%', sm: '60%' },
					}}
				>

				</Box>
			</Box>
			<Box
				sx={{
					pt: { xs: 4, sm: 8 },
					width: '100%',
					borderTop: '1px solid',
					borderColor: 'divider',
				}}
			>
				<Box >
					<Box sx={{ ml: '-15px' }}>
						<img
							src="/logo-no-background.png"
							style={logoStyle}
							alt="logo of sitemark"
						/>
					</Box>
					<Typography variant="body2" fontWeight={600} gutterBottom>
						Do you like to contact us?
					</Typography>
					<Typography variant="body2" color="text.secondary" mb={2}>
						You can write us at <a href="mailto:contact@bizsharekit.com">contact@bizsharekit.co</a>
					</Typography>
				</Box>
				<div>
					<Link color="text.secondary" href="#">
						Privacy Policy
					</Link>
					<Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
						&nbsp;•&nbsp;
					</Typography>
					<Link color="text.secondary" href="#">
						Terms of Service
					</Link>
					<Copyright />
				</div>
				{/*<Stack*/}
				{/*	direction="row"*/}
				{/*	justifyContent="left"*/}
				{/*	spacing={1}*/}
				{/*	useFlexGap*/}
				{/*	sx={{*/}
				{/*		color: 'text.secondary',*/}
				{/*	}}*/}
				{/*>*/}
				{/*	<IconButton*/}
				{/*		color="inherit"*/}
				{/*		href="https://github.com/mui"*/}
				{/*		aria-label="GitHub"*/}
				{/*		sx={{ alignSelf: 'center' }}*/}
				{/*	>*/}
				{/*		<FacebookIcon />*/}
				{/*	</IconButton>*/}
				{/*	<IconButton*/}
				{/*		color="inherit"*/}
				{/*		href="https://twitter.com/MaterialUI"*/}
				{/*		aria-label="X"*/}
				{/*		sx={{ alignSelf: 'center' }}*/}
				{/*	>*/}
				{/*		<TwitterIcon />*/}
				{/*	</IconButton>*/}
				{/*	<IconButton*/}
				{/*		color="inherit"*/}
				{/*		href="https://www.linkedin.com/company/mui/"*/}
				{/*		aria-label="LinkedIn"*/}
				{/*		sx={{ alignSelf: 'center' }}*/}
				{/*	>*/}
				{/*		<LinkedInIcon />*/}
				{/*	</IconButton>*/}
				{/*</Stack>*/}
			</Box>
		</Container>
	);
}
