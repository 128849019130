import * as React from 'react';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import NotFoundPage from "./pages/not-found/NotFoundPage";
import SecretsIdPage, {secretsIdPageLoader} from "./pages/secrets/SecretIdPage";
import SecretsIdWithPasswordPage from "./pages/secrets/SecretIdWithPasswordPage";
import Layout from "./components/Layout";

const router = createBrowserRouter([
	{
		element: <Layout/>,
		errorElement: <NotFoundPage/>,
		children: [
			{
				path: "/",
				element: <LandingPage/>,
			},
			{
				path: "/secrets/:secretId",
				loader: secretsIdPageLoader,
				element: <SecretsIdPage/>,
			},
			{
				path: "/secrets/:secretId/:password",
				element: <SecretsIdWithPasswordPage/>,
			}
		]
	},

])

export default function App() {
	return (
		<RouterProvider router={router}/>
	);
}