import * as React from 'react';
import Box from '@mui/material/Box';
import axios from "axios";
import {alpha, Avatar, CircularProgress, Snackbar} from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import {useCallback, useEffect, useState} from "react";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HistoryIcon from '@mui/icons-material/History';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import {blue} from "@mui/material/colors";
import {useParams} from "react-router-dom";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {Helmet} from "react-helmet";

export const secretsIdWithPasswordPageLoader = async (secretId, password, recaptchaToken) => {
	try {
		const response = await axios.post(
			`${process.env.REACT_APP_API_URL}/secrets/decrypt/${secretId}`,
			{password: password},
			{headers: {recaptcha: recaptchaToken}});
		return {content: response.data.data};
	} catch (e) {
		return {error: e.response.data};
	}
}

const Loader = () => {
	return (
		<Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
			<CircularProgress sx={{mb: 1}}/>
			<Typography variant="h6">Verifying your secret</Typography>
		</Box>
	)
}

export default function SecretsIdWithPasswordPage({params}) {
	const {secretId, password} = useParams();
	const [header, setHeader] = useState(null);
	const [icon, setIcon] = useState(<LockOutlinedIcon/>);
	const [content, setContent] = useState(null);
	const [error, setError] = useState(null);
	const [token, setToken] = useState(null);
	const [loading, setLoading] = useState(true);
	const {executeRecaptcha} = useGoogleReCaptcha();

	// Create an event handler so you can call the verification on button click event or form submit
	const handleReCaptchaVerify = useCallback(async () => {
		if (!executeRecaptcha) {
			console.log('Execute recaptcha not yet available');
			return;
		}

		const token = await executeRecaptcha();
		setToken(token);
		// Do whatever you want with the token
	}, [executeRecaptcha]);

	// You can use useEffect to trigger the verification as soon as the component being loaded
	useEffect(() => {
		handleReCaptchaVerify();
	}, [handleReCaptchaVerify]);

	useEffect(() => {
		if (error) {
			if (error.idInvalid) {
				setHeader({title: "Invalid Secret ID", description: "The provided secret ID is not valid."});
				setIcon(<HighlightOffIcon/>);
			}
			if (error.used) {
				setHeader({title: "Secret Used", description: "The secret has been already been used."});
				setIcon(<HistoryIcon/>);
			}
			if (error.notFound) {
				setHeader({title: "Secret not found", description: "The provided secret is not found."});
				setIcon(<HighlightOffIcon/>);
			}
		}
	}, [error]);

	useEffect(() => {
		if (content) {
			setIcon(<LockOpenOutlinedIcon/>);
		}
	}, [content]);

	useEffect(() => {
		async function fetchData() {
			if (token && !error && !content) {
				const {content, error} = await secretsIdWithPasswordPageLoader(secretId, password, token);
				if (error) {
					setError(error);
				}
				if (content) {
					setContent(content);
				}
				setLoading(false);
			}
		}

		fetchData();
	}, [content, error, password, secretId, token]);


	const [snackbarText, setSnackbarText] = React.useState("");
	const [snackbarOpen, setSnackbarOpen] = React.useState(false);
	const snackbarHandleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setSnackbarOpen(false);
	};

	const snackbar = (text) => {
		setSnackbarText(text);
		setSnackbarOpen(true);
		setTimeout(() => setSnackbarOpen(false), 5000);
	}

	const copyToClipboard = (text, message) => {
		navigator.clipboard.writeText(text)
			.then(() => {
				snackbar(message || 'Your text has been copied!');
			})
			.catch((error) => {
				console.error('Failed to copy text: ', error);
			});
	};

	return (
		<>
			<Helmet>
				<title>Unlock your secret text | BizShareKit</title>
				<meta name="description" content="Unlock your text secret secure with our app."/>
			</Helmet>
			<Box
				id="hero"
				sx={(theme) => ({
					width: '100%',
					backgroundImage:
						theme.palette.mode === 'light'
							? 'linear-gradient(180deg, #CEE5FD, #FFF)'
							: `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
					backgroundSize: '100% 20%',
					backgroundRepeat: 'no-repeat',
				})}
			>
				<Container
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						pt: {xs: 14, sm: 20},
						pb: {xs: 8, sm: 12},
					}}
				>
					<Box
						sx={(theme) => ({

							alignSelf: 'center',
							width: '100%',
							backgroundSize: 'cover',
							borderRadius: '10px',
							outline: '1px solid',
							outlineColor:
								theme.palette.mode === 'light'
									? alpha('#BFCCD9', 0.5)
									: alpha('#9CCCFC', 0.1),
							boxShadow:
								theme.palette.mode === 'light'
									? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
									: `0 0 24px 12px ${alpha('#033363', 0.2)}`,
							minHeight: 480
						})}
					>


						<Box
							sx={{
								margin: 8,
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
							}}
						>
							{loading && <Loader/>}
							{!loading && (
								<Avatar sx={{m: 1, bgcolor: blue[500]}}>
									{icon}
								</Avatar>
							)}
							{header && (
								<>
									<Typography component="h1" variant="h4">
										{header.title}
									</Typography>
									<Typography component="p" variant="p">
										{header.description}
									</Typography>
								</>
							)}
							{!loading && !error && (
								<>
									<TextField
										InputProps={{
											endAdornment: <Button
												onClick={() => copyToClipboard(content, "Your secret text has been copied!")}><CopyAllIcon/> Copy</Button>
										}}
										margin="normal"
										value={content}
										label="Secret text"
										multiline
										rows={10}
										fullWidth
									/>
								</>
							)}
						</Box>
					</Box>
				</Container>
			</Box>
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={5000}
				onClose={snackbarHandleClose}
				message={snackbarText}
			/>
		</>
	)
		;
}
