import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Hero from '../components/Hero';
import Features from '../components/Features';
import FAQ from '../components/FAQ';
import {Helmet} from "react-helmet";


export default function LandingPage() {

	return (
		<>
			<Helmet>
				<title>Encrypt Texts Securely for Safe Sharing | BizShareKit</title>
				<meta name="description" content="Encrypt your sensitive messages and securely share them with anyone. We ensure end-to-end encryption for maximum security and privacy." />
			</Helmet>
			<Hero />
			<Box sx={{ bgcolor: 'background.default' }}>
				<Features />
				<Divider />
				<FAQ />
				<Divider />
			</Box>
		</>
	);
}
